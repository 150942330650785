import angular from 'angular';

var app = angular.module('Incremental', ['ui.bootstrap']);

export default app;









