// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../images/paypal.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../images/reddit.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../images/patreon.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"footer navbar-fixed-bottom\"> <div class=\"container\" id=\"support\"> <a href=\"\" ng-click=\"$parent.showVersion()\">Version {{$parent.version}}</a> Meredori &copy; 2021 <a id=\"paypal\" href=\"https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KWC55P8UFP3AN\" target=\"_blank\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" style=\"width:35px\"/> </a> <a id=\"reddit\" href=\"http://www.reddit.com/r/heroville/\" target=\"_blank\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" style=\"width:35px\"/> </a> <a id=\"patreon\" href=\"http://www.patreon.com/meredori\" target=\"_blank\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" style=\"width:35px\"/> </a> </div> </div> <div id=\"version\" title=\"Version Information\">Current Version: 1.4 - 16 September 2021<br/> <div id=\"confirm\" title=\"Confirmation Required\"> This change is permenant, are you sure this is what you want to do? </div> <ul> <li>Massive code refactor to help with future updates</li> <li>Temporarily removed dark theme</li> </ul> </div>";
// Exports
export default code;