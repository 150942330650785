import app from "../app";

//List of potions with "type" 1: After Combat, 2: On Hero Damage, 3: On Enemy damage, 4: Start of Dungeon
app.value('potions',[
    {
      id: 0,
      name: "Regeneration",
      image: require("../images/P_Green02.png"),
      description: "Restores 2% Health each turn during the fight",
      prodTime: 5,
      progress: "Create Regeneration Potion",
      sellPrice: 3,
      count: 0,
      maxCount: 5,
      maxHero: 10,
      type: 3,
      cost: 100,
      active: false,
      value: 2,
      working: 0,
    },
    {
      id: 1,
      name: "Power",
      image: require("../images/P_Orange02.png"),
      description: "Multiplies damage by 1.5 for one fight",
      prodTime: 5,
      progress: "Create Power Potion",
      sellPrice: 4,
      count: 0,
      maxCount: 5,
      maxHero: 10,
      type: 2,
      cost: 100,
      active: false,
      value: 1.5,
      working: 0,
    },
    {
      id: 2,
      name: "Health",
      image: require("../images/P_Red02.png"),
      description: "Restores 50 Health when consumed",
      prodTime: 5,
      progress: "Create Health Potion",
      sellPrice: 5,
      count: 0,
      maxCount: 5,
      maxHero: 10,
      type: 1,
      cost: 100,
      active: false,
      value: 50,
      working: 0,
    },
    {
      id: 3,
      name: "Good Health",
      image: require("../images/P_Red03.png"),
      description: "Restores 100 Health when consumed",
      prodTime: 5,
      progress: "Create Good Health Potion",
      sellPrice: 8,
      count: 0,
      maxCount: 5,
      maxHero: 10,
      type: 1,
      cost: 100,
      active: false,
      value: 100,
      working: 0,
    },
    {
      id: 4,
      name: "Greater Health",
      image: require("../images/P_Red01.png"),
      description: "Restores 200 Health when consumed",
      prodTime: 5,
      progress: "Create Greater Health Potion",
      sellPrice: 15,
      count: 0,
      maxCount: 5,
      maxHero: 10,
      type: 1,
      cost: 100,
      active: false,
      value: 200,
      working: 0,
    },
  ]);